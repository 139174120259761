import React from 'react';
import { Navigate } from 'react-router-dom';
import UserProfile from './Funcs/UserProfile';

const PrivateRoute = ({ element: Element }) => {
  // Check if there is a userId in local storage to determine authentication
  const isAuthenticated = Boolean(UserProfile.getId());
  return isAuthenticated ? <Element /> : <Navigate to="/login" />;
};

export default PrivateRoute;
