var UserProfile = (function() {
  var getId = function() {
    return sessionStorage.getItem('userId') || "";
  };

  var setId = function(name) {
    sessionStorage.setItem('userId', name);
  };
  var getEmail = function() {
    return sessionStorage.getItem('userEmail') || "";
  };

  var setEmail = function(name) {
    sessionStorage.setItem('userEmail', name);
  };
 
  return {
    getId: getId,
    setId: setId,
    getEmail: getEmail,
    setEmail: setEmail,

  }
})();

export default UserProfile;
